import { Container } from "components/container/Container";
import { RichText } from "components/contentful/rich-text/RichText";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import s from "./IconsTextList.scss";
import { Image } from "components/contentful/image/Image";

const Item = ({ item }: any) => {
  return (
    <div className={s("item")}>
      {item.image && (
        <GatsbyImage
          objectFit="contain"
          className={s("image")}
          image={item.image.gatsbyImageData}
          alt={item.image.description}
        />
      )}
      {item.text && (
        <div className={s("itemText")}>
          <RichText richText={item.text} />
        </div>
      )}
    </div>
  );
};

export const IconsTextList = ({ list }: any) => {
  const { title, text, items } = list;

  const oneItem = {
    image: list.image,
    description: list?.description
  };
  return (
    <Container>
      <div className={s("container")}>
        {oneItem && (
          <div className={s("item")}>
            {oneItem.image && (
              <Image image={oneItem.image} className={s.logo} />
            )}
          </div>
        )}
        <div className={s("header")}>
          {title && <h3 className={s("title")}>{title}</h3>}
          {text && (
            <p className={s("description")}>{<RichText richText={text} />}</p>
          )}
        </div>
        {items && (
          <div className={s("items")}>
            {items.map((item: any, i: number) => (
              <Item key={i} item={item} />
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};
