import { Container } from "../container/Container";
import { RichText } from "../contentful/rich-text/RichText";
import { GatsbyImage } from "gatsby-plugin-image";

import React, { useContext } from "react";
import s from "./InfoImageList.scss";
import { Image } from "../contentful/image/Image";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import NextIcon from "assets/svg/carousel/carousel-next.svg";
import PrevIcon from "assets/svg/carousel/carousel-prev.svg";
import "./carouselOverride.css";
import { UIContext } from "context/ui";

export const Next = () => {
  return (
    <button className={s("button", "next")}>
      <NextIcon className={s("icon")} />
    </button>
  );
};

export const NextDesktopOnly = () => {
  return (
    <button className={s("button", "next", "desktopOnly")}>
      <NextIcon className={s("icon")} />
    </button>
  );
};

export const PrevDesktopOnly = () => {
  return (
    <button className={s("button", "prev", "desktopOnly")}>
      <PrevIcon className={s("icon")} />
    </button>
  );
};

export const Prev = () => {
  return (
    <button className={s("button", "prev")}>
      <PrevIcon className={s("icon")} />
    </button>
  );
};

const Item = ({ item }: any) => {
  return (
    <div className={s("item")}>
      <div className={s("itemImage")}>
        <GatsbyImage
          alt={item.image.description}
          image={item.image.gatsbyImageData}
        />
      </div>
      <div className={s("itemInfo")}>
        {item.title && (
          <div className={s("itemTitle")}>
            <h4>{item.title}</h4>
          </div>
        )}
        {item.text && (
          <div className={s("itemText")}>
            <RichText richText={item.text} />
          </div>
        )}
      </div>
    </div>
  );
};

export const InfoImageList = ({ list, style }: any) => {
  const { isMobile, isDesktop } = useContext(UIContext);

  const items = list.items.map((item: any, i: number) => {
    if (item) {
      return <Item item={item} key={i} />;
    }
  });
  return (
    <Container>
      <div className={s("container")}>
        <div className={s("infoContainer")}>
          <div className={s("infoTitle")}>
            <h4>{list.title}</h4>
          </div>
          {list.text && (
            <div className={s("infoText")}>
              <RichText richText={list.text} />
            </div>
          )}
        </div>
        <div className={"quotes-carousel " + s("carousel")} style={style}>
          <AliceCarousel
            items={items}
            mouseTracking={!(items.length < 2)}
            infinite
            responsive={{
              0: {
                items: 1
              }
            }}
            disableDotsControls={isDesktop ? true : false}
            touchTracking={!(items.length < 2)}
            renderNextButton={NextDesktopOnly}
            renderPrevButton={PrevDesktopOnly}
          />
        </div>
      </div>
    </Container>
  );
};
