import { Container } from "../container/Container";
import { RichText } from "../contentful/rich-text/RichText";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import s from "./TextImageList.scss";
import { Image } from "../contentful/image/Image";

const Item = ({ item, imgTop = false, noMarginTop = false }: any) => {
  return (
    <div className={s("item")}>
      {item.image && imgTop && (
        <div className={s("image")}>
          <Image image={item.image} srcSetMobile={true} />
        </div>
      )}
      <div className={s("itemInfo")}>
        {item.title && (
          <div className={s("itemTitle", { imgTop })}>
            <h4>{item.title}</h4>
          </div>
        )}
        {item.text && (
          <div className={s("itemText")}>
            <RichText richText={item.text} />
          </div>
        )}
      </div>

      {item.image && !imgTop && (
        <div className={s("image")}>
          <Image image={item.image} srcSetMobile={true} />
        </div>
      )}
    </div>
  );
};

export const TextImageList = ({
  list,
  imgTop = false,
  twoElements = false,
  noMarginTop = false
}: any) => {
  const items = list.items;

  return (
    <Container>
      <div className={s("container", { noMarginTop })}>
        {items && (
          <div className={s("items", { twoElements })}>
            {items.map((item: any, i: number) => (
              <Item key={i} item={item} imgTop={imgTop} />
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};
